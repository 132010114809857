@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200..700&display=swap');

body{
  background: #fafafa;
  
/* background: linear-gradient(90deg, rgb(226, 226, 226) 0%, rgb(186, 187, 189) 35%, rgb(199, 199, 199) 100%); */
}
.flip {
    position: relative;
    width: 400px;
    height: 220px;
    margin-right: 2px;
    margin-bottom: 1em;
    perspective: 1000px;
  }
  
  .flip .front, .flip .back {
    width: 100%;
    height: 100%;
    transition: transform 0.5s cubic-bezier(.175, .885, .32, 1.275);
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
  }
  
  .flip .front {
    transform: rotateY(0deg);
  }
  
  .flip .back {
    transform: rotateY(-180deg);
    opacity: 0;
  }
  
  .flip:hover .front {
    transform: rotateY(180deg);
  }
  
  .flip:hover .back {
    opacity: 1;
    transform: rotateY(0deg);
  }
  
  .flip.flip-vertical .back {
    transform: rotateX(-180deg);
  }
  
  .flip.flip-vertical:hover .front {
    transform: rotateX(180deg);
  }
  
  .flip.flip-vertical:hover .back {
    transform: rotateX(0deg);
  }
  




  /* body {
    overflow: hidden;
    margin: 0;
    padding: 0;
  } */
  


.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  margin-top: 50px;
  margin-left: 30px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #eeeeee;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #ff9800;
  color: white;
  transform: rotateY(180deg);
}





/*Style For About Page */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
.col h3 {
  margin: 0;
}
.circle {
  width: 125px;
  height: 125px;
  border: 15px solid transparent;
  border-radius: 50%;
  margin: 0;
  position: relative;
}
.circle1 {
  border-color: #29bdcc;
  border-left-color: transparent;
  border-top-color: transparent;
}
.circle2 {
  border-color: #17556f;
  border-right-color: transparent;
}
.circle3 {
  border-color: #f58c31;
  border-left-color: transparent;
}
.circle4 {
  border-color: #ed4565;
  border-right-color: transparent;
}
.circle5 {
  border-color: #f0b224;
  border-left-color: transparent;
}
.circle6 {
  border-color: #1eb473;
  border-right-color: transparent;
}
.circle7 {
  border-color: #93288d;
  border-left-color: transparent;
}
.circle8 {
  border-color: #1b5f8c;
  border-right-color: transparent;
}
.circle h2 {
  display: table;
  margin: 5px 0 0 5px;
  width: 85px;
  height: 85px;
  background: #29bdcc;
  border-radius: 50%;
}
.circle2 h2 {
  background: #17556f;
}
.circle3 h2 {
  background: #f58c31;
}
.circle4 h2 {
  background: #ed4565;
}
.circle5 h2 {
  background: #f0b224;
}
.circle6 h2 {
  background: #1eb473;
}
.circle7 h2 {
  background: #93288d;
}
.circle8 h2 {
  background: #1b5f8c;
}
.circle span {
  font-size: 12px;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.circle i,
.circle b {
  font-weight: bold;
  font-style: normal;
  display: block;
}
.circle i {
  font-size: 24px;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 980px;
  margin: auto;
}
.col {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  margin-bottom: -47px;
  position: relative;
  left: 23px;
}
.col2 {
  left: -24px;
}
.col1 {
  text-align: right;
  align-content: flex-end;
}
.col1 .content {
  order: -1;
}
.content {
  flex: 1 0 0%;
  padding: 1px 10px;
  margin:-20px 0;
}
.content h2 {
  font-size: 1rem;
}
.content p {
  margin: 0.5em 0 0 0;
  font-size: 0.8rem;
  color: #666;
}
.circle1:before {
  content: "";
  height: 15px;
  width: 250px;
  position: absolute;
  right: 46px;
  top: -15px;
  background: #29bdcc;
  background:linear-gradient(to left, #29bdcc 0%, white 100%);
  z-index: -1;
  animation:shimmer 10s infinite forwards;
}
@keyframes shimmer{
  0% {width: 0;}
  5%{width: 250px;}
  100%{width: 250px;}
}
.circle1:after,
.circle8:after {
  content: "";
  position: absolute;
  left: -15px;
  top: -15px;
  width: 125px;
  height: 125px;
  border: 15px solid transparent;
  border-color: #29bdcc;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-45deg);
  border-radius: 50%;
  z-index: -1;
}
.circle8:after {
  border-color: #1b5f8c;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transform: rotate(-45deg);
}
.circle8:before {
  content: "";
  height: 15px;
  width: 250px;
  position: absolute;
  right: -202px;
  bottom: -15px;
  background: #1b5f8c;
  background:linear-gradient(to right, #1b5f8c 0%, white 100%);
  z-index: -1;
}
.circle8 {
  border-bottom-color: transparent;
}
@media screen and (max-width: 600px) {
  .wrap {
    display: block;
  }
  .content{margin:0;}
  .col {
    margin: 10px 0;
    position: static;
  }
  .circle8:before,
  .circle8:after,
  .circle1:before,
  .circle2:after {
    display: none;
  }
  .circle1 {
    border-color: #29bdcc;
  }
  .circle2 {
    border-color: #17556f;
  }
  .circle3 {
    border-color: #f58c31;
  }
  .circle4 {
    border-color: #ed4565;
  }
  .circle5 {
    border-color: #f0b224;
  }
  .circle6 {
    border-color: #1eb473;
  }
  .circle7 {
    border-color: #93288d;
  }
  .circle8 {
    border-color: #1b5f8c;
  }
}


/* the following css code is just for fun and can be removed*/
/* it applies the shimmer down the spiral using <div class="shim"> which can also be removed */
.shim{
  position:absolute;
  z-index:2;
  left:0;
  top:0;
  margin-top:0;
  width:100%;
  height:100%;
  animation:shim 1s linear forwards;
  transform:rotate(-90deg);
}
.shim:after{
  content:"";
  position:absolute;
  left:50%;
  top:-15px;
  margin-left:-8px;
  width:15px;
  height:15px;
  background:radial-gradient(#fff, rgba(255,255,255,0));
}
.shimx{transform:rotate(90deg);}
.shimx{animation:shim2 1s linear .75s forwards;}

.shim2{animation-delay:.75s}
.shim3{animation-delay:1.5s}
.shim4{animation-delay:2.25s}
.shim5{animation-delay:3s}
.shim6{animation-delay:3.75s}
.shim7{animation-delay:4.5s}
.shim8{animation-delay:5.25s}


@keyframes shim {
  from{transform:rotate(-90deg);}
  to{transform:rotate(270deg);}
}
@keyframes shim2 {
  from{transform:rotate(90deg);}
  to{transform:rotate(-270deg);}
}



/* Mission */
@layer utilities {
  @keyframes slide {
    0% {
      top: 0;
    }
    25% {
      top: -4rem;
    }
    50% {
      top: -8rem;
    }
    72.5% {
      top: -12.25rem;
    }
  }
  
  @keyframes slide-mob {
    0% {
      top: 0;
    }
    25% {
      top: -2.125rem;
    }
    50% {
      top: -4.25rem;
    }
    72.5% {
      top: -6.375rem;
    }
  }
  
  .animate-slide {
    animation: slide 5s infinite;
  }
  
  .animate-slide-mob {
    animation: slide-mob 5s infinite;
  }
}


/* Text */
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,900,400italic,700italic,900italic');

@layer base {
  body {
    font-family: 'Raleway', sans-serif;
  }
}

@layer utilities {
  @keyframes text-shadow {
    0% {
      transform: translateY(0);
      text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
    }
    20% {
      transform: translateY(-1em);
      text-shadow: 0 0.125em 0 #0c2ffb, 0 0.25em 0 #2cfcfd,
        0 -0.125em 0 #fb203b, 0 -0.25em 0 #fefc4b;
    }
    40% {
      transform: translateY(0.5em);
      text-shadow: 0 -0.0625em 0 #0c2ffb, 0 -0.125em 0 #2cfcfd,
        0 0.0625em 0 #fb203b, 0 0.125em 0 #fefc4b;
    }
    60% {
      transform: translateY(-0.25em);
      text-shadow: 0 0.03125em 0 #0c2ffb, 0 0.0625em 0 #2cfcfd,
        0 -0.03125em 0 #fb203b, 0 -0.0625em 0 #fefc4b;
    }
    80% {
      transform: translateY(0);
      text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
    }
  }

  .animate-text-shadow {
    animation: text-shadow 1.5s ease-in-out infinite;
  }

  .pause-animation {
    animation-play-state: paused;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}


/* Carousal */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Preloader */
.body {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  background: #ececec;
}

.circle-container {
  position: relative;
  height: 70px;
  width: 70px;
}

.circle-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 5px solid #ACCAFF36;
  border-radius: 50%;
}

.circle-progress::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #ACCAFF;
  top: -5px;
  left: -5px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 1s ease-out;
}
.page-number--ones {
  transform: translateY(calc((-100% * (var(--percentage) - 1/var(--total)))));
}
.landscape-text {
  background-size: 160% auto;
  background-position: calc(80% + (-70% * var(--percentage))) 0;
  transform: translateX(calc(40% + (-100% * var(--percentage))));
}






  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

 
  .list ul li::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #f50057;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
  }
  
  .list ul li:hover::before {
    transform: scaleX(1);
  }
  
  .list ul li:hover {
    padding-left: 1.25rem; /* Equivalent to pl-5 */
  }
  
  .list ul li:hover span {
    color: #111; /* Equivalent to text-gray-900 */
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader {
    animation: rotation 1s linear infinite;
  }
  
  /* Reel */
  /* Horizontal scrolling container */
  .reel-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

/* The reel that scrolls */
.reel {
  display: inline-flex;
  animation: scroll 20s linear infinite;
}

/* Individual image container */
.image-container {
  flex: 0 0 auto;
  width: 390px; /* Adjust width as needed */
  height: 170px; /* Adjust height as needed */
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0; /* Light background to show any areas not covered by image */
}

/* Image styling */
.image {
  width: 1000%;
  height: 100%;
  object-fit: cover;
}

/* Keyframes for scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
